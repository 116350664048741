import React from 'react';

const HeroSection = () => {
  return (
    <section className="bg-gray-100 dark:bg-gray-800 text-center py-16">
      <h1 className="text-4xl font-bold text-gray-800 dark:text-white">Welcome to Edu AI ART Blog</h1>
      <p className="mt-4 text-gray-600 dark:text-gray-300">Discover articles, stories, and more!</p>
    </section>
  );
};

export default HeroSection;
