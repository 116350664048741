import React from 'react';

const PostPreview = ({ title, date, summary, link }) => {
  return (
    <div className="bg-white dark:bg-gray-700 p-4 shadow rounded-lg">
      <h2 className="text-xl font-bold text-gray-800 dark:text-white">{title}</h2>
      <p className="text-gray-500 dark:text-gray-300 text-sm">{date}</p>
      <p className="mt-2 text-gray-600 dark:text-gray-200">{summary}</p>
      <a href={link} className="text-blue-500 dark:text-blue-300 hover:underline mt-4 block">
        Read More
      </a>
    </div>
  );
};

export default PostPreview;
