import React from "react";

const AcornAdventure = () => {
  return (
      <div className="container mx-auto my-8 px-4">
      <h1 className="text-4xl font-bold mb-6 text-gray-800 dark:text-white">The Acorn Adventure with Anaya and Ayansh</h1>
      

      {/* Part 1: The Falling Acorns */}
      <section>
      <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-800 dark:text-white">
      Part 1: The Falling Acorns
      </h2>
        
        <article>
          <p>
            <strong>"Plop!"</strong> Anaya picked up an acorn that had just fallen.{" "}
            <strong>"Ayansh, these acorns are everywhere! Where do they come from?"</strong>{" "}
            she wondered aloud.
          </p>
          <p>
            Ayansh, always curious, replied, <strong>"I think they come from the oak trees."</strong>
          </p>
          <p>
            Mrs. Cardinal, a wise bird perched nearby, added,{" "}
            <strong>
              "That's right! Acorns are the seeds of oak trees. They fall so new trees can grow, or
              they become food for animals."
            </strong>
          </p>
          <p>
            Anaya's eyes lit up. <strong>"That's so cool!"</strong> she said as another acorn
            bounced off Ayansh's hat, making them both laugh.
          </p>
          <p>
            <strong>"I have an idea!"</strong> Ayansh exclaimed.{" "}
            <strong>"Let’s use these acorns to make pictures on the ground!"</strong>
          </p>
          <p>
            They eagerly gathered acorns, creating a smiley face. Soon, they decided to invite their
            friends to join the fun.
          </p>
        </article>

        {/* Image Example */}
        <figure className="mb-8">
          <img 
            src="/images/Acorns.webp" 
            alt="Acorn art example"
            className="rounded-lg shadow-lg w-full h-auto"
          />
          <figcaption className="text-sm text-gray-500 text-center mt-2">
            Acorns inspire creativity and fun in nature.
          </figcaption>
        </figure>
      </section>

      {/* Part 2: The Surprise in the Woods */}
      <section>
      <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-800 dark:text-white">
      Part 2:  The Surprise in the Woods
      </h2>
        <article>
          <p>
            As Anaya and Ayansh strolled along the forest path, dodging falling acorns, they suddenly
            heard a rustling sound in the bushes.
          </p>
          <p>
            <strong>"What's that?"</strong> Anaya asked, taking a cautious step closer. Before Ayansh
            could answer, two wild turkeys burst out from the bushes, gobbling loudly!
          </p>
          <p>
            <strong>"Run!"</strong> shouted Ayansh, grabbing Anaya’s hand as they dashed down the
            path. The turkeys chased after them, their feathers flapping wildly.
          </p>
          <p>
            When the turkeys finally gave up, the duo stopped, panting. Ayansh laughed nervously.{" "}
            <strong>"Phew! Those turkeys were fast!"</strong>
          </p>
          <p>
            <strong>"They were gobbling like crazy!"</strong> Anaya added with a giggle.{" "}
            <strong>"But we escaped!"</strong>
          </p>
        </article>
      </section>

      {/* Part 3: Creative Acorn Art */}
      <section>
        <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-800 dark:text-white">
      Part 3:  Creative Acorn Art
      </h2>
        <article>
          <p>
            Once they calmed down, Anaya and Ayansh gathered their friends to make acorn art. Flora
            the skunk, Mitzi the mink, and Bizzie the beaver joined in.
          </p>
          <p>
            Anaya made a flower with red leaves, Ayansh created a long-tailed squirrel, and their
            friends added a ladybug and a spiral design.
          </p>
          <p>
            <strong>"How do you like my squirrel?"</strong> Ayansh asked proudly.
          </p>
          <p>
            <strong>"It's a masterpiece!"</strong> Anaya declared with a big grin.
          </p>
          <p>
            Just as they admired their creations, they heard the turkeys again. The friends panicked,
            but Mrs. Cardinal chirped, <strong>"Wait! Look!"</strong>
          </p>
          <p>
            To their surprise, the turkeys weren’t chasing them anymore—they were busy gobbling up the
            acorns from their art!
          </p>
          <p>
            <strong>"Turkeys like acorns!"</strong> Anaya laughed.
          </p>
          <p>
            <strong>"It’s okay,"</strong> Ayansh said.{" "}
            <strong>
              "We can always make more pictures. I’m just glad it’s the acorns getting gobbled and not
              us!"
            </strong>
          </p>
        </article>
      </section>

      {/* Footer */}
      <footer>
        <p>
        <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-800 dark:text-white">
        Through teamwork and creativity, Anaya and Ayansh turned a wild turkey chase into a fun
        and artistic adventure they’d never forget.
      </h2>
          
        </p>
        <h3>The End</h3>
      </footer>
    </div>
  );
};

export default AcornAdventure;
