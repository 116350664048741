import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import DarkModeToggle from './components/DarkModeToggle';
import PostPreview from './components/PostPreview';
import Footer from './components/Footer';
import SearchBar from './components/SearchBar';

// Import additional pages
import EducationSystem from './pages/EducationSystem';
import FirstBlogPost from './pages/FirstBlogPost';
import CoachingForBehaviourChange from './pages/CoachingForBehaviourChange';
import AcornAdventure from './pages/AcornAdventure';

function App() {
  const [searchTerm, setSearchTerm] = useState('');
  const posts = [
    {
      title: 'The Importance of Global Education: A Worldwide Perspective',
      date: 'Nov 16, 2024',
      summary: 'Education is a fundamental human right vital for personal and societal growth. This blog emphasizes the importance of staying informed about global education trends, challenges, and successes. By updating everyone worldwide, we can collectively work towards ensuring that quality education becomes accessible and important to every individual everywhere.',
      link: '/first-blog-post'
    },
    {
      title: 'Coaching for Behaviour Change',
      date: 'Nov 14, 2024',
      summary: 'Coaching for Behaviour Change empowers individuals to achieve personal and professional growth by breaking old habits and developing new, positive ones. Through goal-setting, accountability, emotional support, and tailored strategies, coaches guide clients toward sustainable transformation, helping them overcome challenges and align actions with values for meaningful, lasting change.',
      link: '/CoachingForBehaviourChange'
    },
    {
      title: 'Education System: How America Compares to the World',
      date: 'Nov 16, 2024',
      summary: `Education is the cornerstone of any society's progress and development. This blog explores the differences 
      between the American education system and global practices, delving into funding, policies, student outcomes, and 
      potential lessons for improvement.`,
      link: '/education-system' // Link to the detailed page
    },
    {
      title: 'The Acorn Adventure with Anaya and Ayansh',
      date: 'Nov 16, 2024',
      summary: `Anaya and Ayansh explore a forest filled with falling acorns, learning about their role in nature. A surprise turkey chase adds excitement, but the friends turn the adventure into creative fun by making acorn art with animal friends. In the end, teamwork and laughter make it an unforgettable day!`,
      link: '/AcornAdventure' // Link to the detailed page
    }
  ];

  // Filter posts based on the search term
  const filteredPosts = posts.filter(
    (post) =>
      post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      post.summary.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Router>
      <Navbar />
      <HeroSection />
      <div className="container mx-auto my-8 px-4">
        <DarkModeToggle />
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <Routes>
          {/* Route for the main page with posts */}
          <Route
            path="/"
            element={
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-8">
                {filteredPosts.length > 0 ? (
                  filteredPosts.map((post, index) => (
                    <PostPreview
                      key={index}
                      title={post.title}
                      date={post.date}
                      summary={post.summary}
                      link={post.link}
                    />
                  ))
                ) : (
                  <p className="text-gray-600 dark:text-gray-200">
                    No posts found matching your search.
                  </p>
                )}
              </div>
            }
          />
          {/* Route for the detailed blog page */}
          <Route path="/education-system" element={<EducationSystem />} />
          <Route path="/first-blog-post" element={<FirstBlogPost />} />
          <Route path="/CoachingForBehaviourChange" element={<CoachingForBehaviourChange />} />
          <Route path="/AcornAdventure" element={<AcornAdventure />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
