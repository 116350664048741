import React from 'react';

const FirstBlogPost = () => (
    <div className="container mx-auto my-8 px-4">
      <h1 className="text-4xl font-bold mb-6 text-gray-800 dark:text-white">
        The Importance of Global Education: A Worldwide Perspective
      </h1>

      <p className="text-lg leading-7 text-gray-700 dark:text-gray-300 mb-6">
        Education is a fundamental human right and a powerful tool for personal and societal development. 
        In an increasingly interconnected world, understanding the state of education globally is more 
        important than ever. This blog aims to update everyone on the current trends, challenges, and 
        advancements in education across different countries, highlighting why education matters to all of us.
      </p>

      {/* Image Example */}
      <div className="mb-8">
        <img 
          src="/images/global.webp" 
          alt="Global Education"
          className="rounded-lg shadow-lg w-full h-auto"
        />
        <p className="text-sm text-gray-500 text-center mt-2">
          Global Education empowers communities and fosters equality.
        </p>
      </div>

      <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-800 dark:text-white">
        Why Global Education Matters
      </h2>
      <p className="text-lg leading-7 text-gray-700 dark:text-gray-300 mb-6">
        Access to quality education empowers individuals to improve their lives, contribute to their 
        communities, and foster economic growth. It promotes understanding, tolerance, and peace among 
        nations. By staying informed about global education, we can work together to address disparities 
        and ensure that everyone has the opportunity to learn and succeed.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-800 dark:text-white">
        Current State of Education Worldwide
      </h2>
      <ul className="list-disc list-inside space-y-4 text-lg leading-7 text-gray-700 dark:text-gray-300">
        <li>
          <strong>Access Inequality:</strong> Over 260 million children and youth are out of school globally, 
          with barriers such as poverty, gender discrimination, and conflict preventing access to education.
        </li>
        <li>
          <strong>Quality of Education:</strong> Many students who are in school are not learning the basics due 
          to inadequate teaching methods, lack of resources, and overcrowded classrooms.
        </li>
        <li>
          <strong>Technological Gaps:</strong> The digital divide has become more apparent, especially during 
          the COVID-19 pandemic, where remote learning is not accessible to all.
        </li>
      </ul>
      <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-800 dark:text-white">
        Conclusion
      </h2>
      <p className="text-lg leading-7 text-gray-700 dark:text-gray-300 mb-6">
        Education is a vital component of individual fulfillment and societal progress. By updating ourselves 
        and others about the state of education worldwide, we can contribute to meaningful change. Let's work 
        together to ensure that education is a reality for everyone, everywhere—because it truly is important 
        to every one of us.
      </p>
    </div>
);

export default FirstBlogPost;
